// Angular
import { Routes } from '@angular/router';
// Caloudi
import environment from '@env';
import * as Component from '.';

const MAIN_UNAUTHURIZED_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: Component.HomeComponent,
    data: { redirectTo: '', path: 'COMMON_PAGE_ROUTES' },
  },
  {
    path: 'onboard/:platform/:role',
    loadChildren: () => import('@onboard/common/onboard-controller.module').then(m => m.OnboardControllerModule),
    data: { route: 'root' },
  },
  {
    path: 'accessdenied',
    component: Component.AccessdeniedComponent,
  },
  {
    path: 'login',
    component: Component.LoginComponent,
  },
  {
    path: 'signup',
    component: Component.SignupComponent,
  },
  {
    path: 'sso',
    component: Component.SsoComponent,
  },
  {
    path: 'sustain-link',
    title: '資安評級YODA',
    loadChildren: () => import('./sustain-link/sustain-link.module').then(m => m.SustainLinkModule),
  },
];

const YSL_UNAUTHURIZED_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: Component.HomeComponent,
    data: { redirectTo: '', path: 'COMMON_PAGE_ROUTES' },
  },
  {
    path: 'accessdenied',
    component: Component.AccessdeniedComponent,
  },
  {
    path: 'login',
    component: Component.LoginYslComponent,
  },
  {
    path: 'signup',
    component: Component.SignupComponent,
  },
  {
    path: 'sso',
    component: Component.SsoComponent,
  },
];

export const UNAUTHURIZED_ROUTES = new RegExp(/^ysl-.*/).test(environment.name)
  ? YSL_UNAUTHURIZED_ROUTES : MAIN_UNAUTHURIZED_ROUTES;
