<div class="pages-body accessdenied-page d-flex flex-column">
  <div class="align-self-center mt-auto mb-auto">
    <p-card class="p-5" [style]="{ background: '#ffffffd0' }">
      <ng-template pTemplate="header">
        <h1 class="p-3 mb-0 text-center">{{ 'ACTION.ACCESS_DENIED' | translate }}</h1>
      </ng-template>

      <div class="m-3">
        <div class="card px-6">
          <img [src]="logoPath()" alt="LOGO" />
        </div>
        <p>Requested resource is not available.</p>
      </div>

      <ng-template pTemplate="footer">
        <div class="d-flex flex-column justify-between px-3 pb-3">
          <button
            pButton
            aria-label="Button"
            type="button"
            [severity]="'primary'"
            class="mb-3"
            [label]="'ACTION.GO_BACK' | translate | titlecase"
            (click)="routeChange()"></button>
          <button
            pButton
            aria-label="Button"
            type="button"
            [severity]="'primary'"
            [label]="'ACTION.LOGOUT' | translate | titlecase"
            (click)="logout()"></button>
        </div>
      </ng-template>
    </p-card>
  </div>
</div>
