<ng-container *ngIf="false && isError && state">
  <div>
    <label>Detail:</label>
    <json-formatter [value]="state.detail"></json-formatter>

    <label>Message:</label>
    <json-formatter [value]="state.message"></json-formatter>

    <dl>
      <dt>Code:</dt>
      <dd>{{ state.code }}</dd>

      <dt>Status:</dt>
      <dd>{{ state.status }}</dd>

      <dt>Is Exist:</dt>
      <dd>{{ state.isExist + '' }}</dd>

      <dt>Redirect Url:</dt>
      <dd>{{ state.redirectUrl || '-' }}</dd>
    </dl>
  </div>
</ng-container>

<div *ngIf="isError && state" class="error_section">
  <onboard-error [state]="state"></onboard-error>
</div>
