<main id="login" #login>
  <div id="login_langs" *ngIf="langFeature">
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'中'"
      (click)="onLangSelect(DocumentLang.CHT)"></button>
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'EN'"
      (click)="onLangSelect(DocumentLang.ENG)"></button>
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'简'"
      (click)="onLangSelect(DocumentLang.CHS)"></button>
    <button
      pButton
      aria-label="Button"
      *ngIf="testingFeature"
      class="lang_button"
      [label]="'測'"
      (click)="capture()"></button>
  </div>

  <section id="login_section" @initAnimation>
    <figure class="login_logo">
      <img [src]="logoPath()" alt="Logo" />
      <p-divider layout="vertical"></p-divider>
      YSL
    </figure>

    <div>
      <textarea name="productKey" id="productKey" cols="30" rows="10" [(ngModel)]="productKey"></textarea>
      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'primary'"
        [label]="'LOGIN.LOGIN_IN' | translate"
        [disabled]="loading || !productKey"
        (click)="onSubmit()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
      </button>
    </div>
  </section>
</main>

<div *ngIf="testingFeature" id="captured_img"></div>
