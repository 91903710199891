// Angular
import { Component, Injector, OnInit } from '@angular/core';
// Rxjs
import { first } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { AuthService } from '@core/service';
import { CommonUtil } from '@util';
// Interface
import { MessageSeverity } from '@core/enum';
import { AuthUser, CommonHttpErrorResponse } from '@core/model';

@Component({
  selector: 'sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.sass'],
})
export class SsoComponent extends BaseComponent implements OnInit {
  constructor(public readonly injector: Injector, private readonly authService: AuthService) {
    super(injector);
  }

  public ngOnInit(): void {
    this.route.fragment.subscribe(frag => {
      const fragItem: SSOFrags = CommonUtil.URLDecode(frag);
      this.logger.trace('sso frag:', [frag, fragItem]);
      this.authService
        .ssoLogin(fragItem.id_token, fragItem.state)
        .pipe(first())
        .subscribe({
          next: (authUser: AuthUser): void => {
            this.logger.trace('sso authUser:', [authUser]);
            this.store.dispatch(this.actions.LoginAction({ authUser: { ...authUser }}));
          },
          error: (e: CommonHttpErrorResponse): void => {
            this.router.navigate(['/login']);
            this.logger.error('Login Error:', e?.stack);
            this.store.dispatch(
              this.actions.GlobalMsgAction({
                severity: MessageSeverity.ERROR,
                summary: e.name,
                message: e.message,
              })
            );
          },
        });
    });
  }
}

interface SSOFrags {
  id_token: string;
  session_state: string;
  state: string;
}
