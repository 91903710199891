// Angular
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Primeng
import { PrimengModule } from '@primeng';
// Caloudi
import * as Component from '.';
import { UNAUTHURIZED_ROUTES } from './unauthurized.routes';
// Module
import { OnboardErrorModule } from '@onboard/common/onboard-shared.module';

@NgModule({
  imports: [
    ReactiveFormsModule,
    PrimengModule,
  ],
  declarations: [
    Component.AccessdeniedComponent,
    Component.HomeComponent,
  ],
  exports: [
    Component.AccessdeniedComponent,
    Component.HomeComponent,
  ]
})
export class BaseUnauthurizedModule { }

@NgModule({
  imports: [
    RouterModule.forChild(UNAUTHURIZED_ROUTES),
    ReactiveFormsModule,
    PrimengModule,
    OnboardErrorModule,
    BaseUnauthurizedModule,
  ],
  declarations: [
    Component.LoginComponent,
    Component.SignupComponent,
    Component.SsoComponent,
  ],
})
export class UnauthurizedModule { }

@NgModule({
  imports: [
    RouterModule.forChild(UNAUTHURIZED_ROUTES),
    ReactiveFormsModule,
    PrimengModule,
  ],
  declarations: [
    Component.LoginYslComponent,
  ],
})
export class YslUnauthurizedModule { }
