// Angular
import { Component, Injector } from '@angular/core';
// Caloiudi
import { BaseComponent } from '@base';

@Component({
  selector: 'accessdenied',
  styleUrls: ['./accessdenied.component.sass'],
  templateUrl: './accessdenied.component.html',
})
export class AccessdeniedComponent extends BaseComponent {

  constructor(public readonly injector: Injector) {
    super(injector);
  }

  public routeChange(): void {
    if (this.authenticationService?.currentUser.value) {
      this.store.dispatch(this.actions.LoginAction({ authUser: this.authenticationService.currentUser.value }));
      this.router.navigate(['/pages']);
    } else window.open(location.origin, '_self');
  }

  public logout(): void {
    this.store.dispatch(this.actions.LogoutAction());
  }
}
