// Angular
import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { ParamMap } from '@angular/router';
// Rxjs
import { first } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import environment from '@env';
import { JSONUtil } from '@util';
// Interface
import { ResponseStatus } from '@core/enum';
import { CommonResponse } from '@core/model';
import { OnboardParamMap } from '@onboard/model';

@Component({
  selector: 'signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['./signup.component.sass'],
})
export class SignupComponent extends BaseComponent implements OnInit {
  public state: SignupRedirectState;
  public isError: boolean;

  public params: OnboardParamMap<SignupParam>;

  constructor(public readonly injector: Injector, private readonly http: HttpClient) {
    super(injector);
  }

  public ngOnInit(): void {
    console.debug('sign up init:', [this.route?.queryParamMap]);
    this.route.queryParamMap.pipe(first()).subscribe({
      next: (params: OnboardParamMap<SignupParam>) => {
        this.params = params;
        this.logger.debug('signup param:', [params, params?.params?.token]);

        if (!params?.params?.token) {
          this.isError = true;
          return;
        }

        /** Main Proccess */
        this.http
          .post<SignupRedirectState>(`${environment.cmpAPIServer.url}/api/saas/purchaseToken`, {
            token: params.params.token,
          })
          .subscribe(res => {
            this.logger.debug('signup check:', [res]);
            this.state = res;
            try {
              if (res.status === ResponseStatus.ERROR) {
                this.isError = true;
                this.state.detail = JSONUtil.parse<SignupRedirectStateDetail>(this.state.detail);
                this.state.detail.responseContent = JSONUtil.parse<string>(this.state.detail.responseContent);
                this.state.message = JSONUtil.parse<string>(
                  String(this.state.message).replace(/resolve purchase token error message=(.*)/i, '$1')
                );
                return;
              }
            } catch (error) {
              return;
            }
            this.logger.debug('redirectUrl:', [res.redirectUrl]);
            if (res.isExist) window.open(res.redirectUrl, '_self');
            else this.router.navigateByUrl(res.redirectUrl, { state: { onboardToken: this.params.params.token }});
          });
      },
      /** Use console.debug for dev testing */
      error: () => {
        console.debug('sign up error:', [location.href]);
      },
    });
  }
}

interface SignupParam extends ParamMap {
  token: string;
}

export interface SignupRedirectState extends CommonResponse {
  redirectUrl: string;
  isExist: boolean;
  detail?: SignupRedirectStateDetail;
}

type SignupRedirectStateDetail = string & { responseContent: string };
