// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { AuthService } from '@core/service/api';
// Interface
import { DocumentLangType } from '@core/enum';
import { AuthUser, CommonHttpErrorResponse } from '@core/model';
// Third Party
import html2canvas from 'html2canvas';

@Component({
  templateUrl: 'login-ysl.component.html',
  styleUrls: ['./login-ysl.component.sass'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('initAnimation', [
      transition(':enter', [
        style({ transform: 'translate(100%, 0)' }),
        animate('.4s cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'translate(0, 0)' })),
      ]),
    ]),
  ],
})
export class LoginYslComponent extends BaseComponent implements OnInit, OnDestroy {

  public DocumentLang = DocumentLangType;

  /** Toggle Language Selector & auto select lang on init */
  public langFeature: boolean = !1;
  public testingFeature: boolean = !1;

  public loading: boolean;
  public submitted: boolean;

  private _productKey: string;
  public set productKey(productKey: string) { this._productKey = productKey; }
  public get productKey(): string { return this._productKey?.trim() || ''; }

  /** TODO: language change by browser */
  public langList: SelectItem<DocumentLangType>[] = [
    { value: DocumentLangType.ENG, label: 'English' },
    { value: DocumentLangType.CHS, label: '简体中文' },
    { value: DocumentLangType.CHT, label: '繁體中文' },
  ];

  public selectedLang: SelectItem<string> = this.langList[0];

  @ViewChild('login') public loginMain: ElementRef<HTMLElement>;
  public capture(): void {
    html2canvas(this.loginMain.nativeElement, { logging: false }).then(canvas => {
      this.logger.debug('canvas:', [canvas]);
      document
        .querySelector('#captured_img')
        .appendChild(document.createElement('img'))
        .setAttribute('src', canvas.toDataURL('image/png'));
    });
  }

  private readonly loginSub = new Subject<AuthUser>();

  constructor(
    public readonly injector: Injector,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    super(injector);
    // Redirect to home if already logged in
    if (this.authenticationService?.currentUser?.value?.userProfile) {
      location.href = location.origin + '/pages';
      return;
    }

    if (this.langFeature) {
      this.logger.debug('lang init:', [navigator.language, navigator.languages]);
      this.store
        .select(this.selectors.layout)
        .pipe(first())
        .subscribe(res => {
          this.logger.debug('lang store:', [res]);
          const lang = res?.lang || navigator.language;
          switch (lang) {
            case 'zh-TW':
              this.bindLang(DocumentLangType.CHT);
              break;
            case 'zh-CN':
              this.bindLang(DocumentLangType.CHS);
              break;
            default:
              this.bindLang(DocumentLangType.ENG);
              break;
          }
        });
    }
    this.logger.debug('history:', [history]);
  }

  public ngOnInit(): void {
    return void 0;
  }

  public onLangSelect(key: DocumentLangType): void {
    this.logger.debug('selected lang:', [key]);
    this.bindLang(key);
  }

  private bindLang(key: DocumentLangType): void {
    this.document.documentElement.lang = key;
    this.selectedLang = this.langList.find(item => item.value === key);
    this.translate.use(key);
    this.store.dispatch(this.actions.ChangeLanguageAction({ lang: key }));
  }

  public onSubmit(): void {
    this.logger.debug('submit:', [this.productKey]);
    this.submitted = true;
    this.loading = true;

    this.authService.yslLogin(this.productKey).pipe(takeUntil(this.loginSub)).subscribe({
      next: authUser => {
        this.logger.debug('authUser:', [authUser]);
        this.store.dispatch(this.actions.LoginAction({ authUser }));
      },
      error: (e: CommonHttpErrorResponse): void => {
        this.logger.debug('error:', [e]);
        this.loading = false;
      },
      complete: () => {
        this.logger.debug('complete');
        this.loading = false;
      },
    });
  }

  public enterCheck(event: KeyboardEvent): boolean {
    return event.code === 'Enter' || event.code === 'NumpadEnter';
  }

  public ngOnDestroy(): void {
    this.loginSub?.unsubscribe();
  }
}
