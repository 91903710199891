<main id="login" #login>
  <div id="login_langs" *ngIf="langFeature">
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'中'"
      (click)="onLangSelect(DocumentLang.CHT)"></button>
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'EN'"
      (click)="onLangSelect(DocumentLang.ENG)"></button>
    <button
      pButton
      aria-label="Button"
      class="lang_button"
      [label]="'简'"
      (click)="onLangSelect(DocumentLang.CHS)"></button>
    <button
      pButton
      aria-label="Button"
      *ngIf="testingFeature"
      class="lang_button"
      [label]="'測'"
      (click)="capture()"></button>
  </div>

  <section id="login_section" *ngIf="!displayPasswdChange && !displayMFA" @initAnimation>
    <figure class="login_logo">
      <img [src]="logoPath()" alt="Logo" />
    </figure>

    <section class="btn_area">
      <button
        pButton
        aria-label="Button"
        type="button"
        [label]="signInAzure"
        [faIcon]="fab.faMicrosoft"
        [disabled]="!ssoRequestConfig"
        (click)="onSSO(AUTHORITY.AZURE)"></button>
      <button
        pButton
        aria-label="Button"
        type="button"
        [label]="signInGCP"
        [faIcon]="fab.faGoogle"
        [disabled]="!ssoRequestConfig"
        (click)="onSSO(AUTHORITY.GCP)"></button>
      <!-- <button pButton
         aria-label="Button"
              type="button"
              [label]="signInAWS"
              [disabled]="!ssoRequestConfig"
              (click)="onSSO(AUTHORITY.AWS)">
        <i class="pi pi-google"></i>
      </button> -->
    </section>

    <section class="split_line">
      <hr />
      <span>{{ 'COMMON.OR' | translate }}</span>
      <hr />
    </section>

    <form ngForm>
      <span class="login_input">
        <label for="user_id">{{ 'LOGIN.USERID' | translate }}</label>
        <input
          pInputText
          required
          #userid
          type="text"
          id="user_id"
          name="user_id"
          autocomplete="current-password"
          [ngClass]="{ 'is-invalid': submitted && (!idValid || !mailValid) }"
          [(ngModel)]="loginItem.userid"
          (ngModelChange)="userIdChange($event)"
          (keyup)="!enterCheck($event) || passwd?.focus()"
          (blur)="idDirty($event)" />
      </span>
      <span *ngIf="submitted && !idIsDirty">
        <p-messages
          *ngIf="!idValid"
          [value]="[{ severity: 'error', detail: lang('LOGIN.MESSAGE.ID_REQUIRED') }]"></p-messages>
      </span>
      <span *ngIf="idIsDirty && (!mailValid || !idValid)" class="message">
        <p-messages
          *ngIf="!idValid"
          [value]="[{ severity: 'error', detail: lang('LOGIN.MESSAGE.ID_REQUIRED') }]"></p-messages>
        <p-messages
          *ngIf="!mailValid"
          [value]="[{ severity: 'error', detail: lang('LOGIN.MESSAGE.EMAIL_FORMAT') }]"></p-messages>
      </span>

      <span class="login_input">
        <label for="user_passwd">{{ 'LOGIN.PASSWORD' | translate }}</label>
        <input
          pInputText
          required
          #passwd
          type="password"
          id="user_passwd"
          name="user_passwd"
          autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && !passwdValid }"
          [(ngModel)]="loginItem.passwd"
          (keyup)="!enterCheck($event) || onSubmit()"
          (ngModelChange)="userPasswdChange($event)"
          (blur)="passwdDirty($event)" />
      </span>
      <span *ngIf="submitted && !passwdIsDirty">
        <p-messages
          severity="error"
          *ngIf="!passwdValid"
          text="'LOGIN.MESSAGE.PASSWD_REQUIRED' | translate"></p-messages>
      </span>
      <span *ngIf="passwdIsDirty && !passwdValid" class="message">
        <p-messages
          *ngIf="!passwdValid"
          [value]="[{ severity: 'error', detail: lang('LOGIN.MESSAGE.PASSWD_REQUIRED') }]"></p-messages>
      </span>

      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'primary'"
        [label]="'LOGIN.LOGIN_IN' | translate"
        [disabled]="loading || !loginItem.valid"
        (click)="onSubmit()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
      </button>
    </form>

    <!-- <p-dropdown [options]="langList"
                [filter]="false"
                [ngModel]="selectedLang.value"
                [appendTo]="'body'"
                [style]="{ 'flex': '1 100%' }"
                (ngModelChange)="onLangSelect($event)">
    </p-dropdown> -->
  </section>

  <ng-template *ngIf="displayPasswdChange && !displayMFA" [ngTemplateOutlet]="resetPasswd"></ng-template>
  <ng-template *ngIf="!displayPasswdChange && displayMFA" [ngTemplateOutlet]="mfaCheck"></ng-template>
</main>

<ng-template #resetPasswd>
  <section id="reset_password">
    <section class="info_header">
      <div>
        <button pButton aria-label="Button" type="button" [faIcon]="fas.faArrowLeft" (click)="goBack()"></button>
        <h4 [textContent]="preAuthStatus?.preAuthMessage || ''"></h4>
      </div>

      <section class="policy_info" *ngIf="passwdForm">
        <ul>
          <li
            *ngIf="
              passwdForm.newPasswd.invalid &&
              passwdForm.newPasswd.value.length < preAuthStatus.passwordPolicy.minPasswordLength
            ">
            {{ lengthMismatch }}
          </li>
          <li
            *ngIf="
              (passwdForm.newPasswd.invalid && passwdForm.newPasswd.value.length === 0) ||
              passwdForm.newPasswd.errors?.classesMismatch
            ">
            {{ classesMismatch }}
          </li>
          <li
            *ngIf="
              (passwdForm.newPasswd.invalid && passwdForm.newPasswd.value.length === 0) ||
              (passwdForm.newPasswd.value === loginItem.userid &&
                !preAuthStatus.passwordPolicy?.passwordCanBeSameAsUserId)
            ">
            {{ matchUserId }}
          </li>
          <li *ngIf="passwdForm.confirmPasswd.invalid && passwdForm.confirmPasswd.errors.passwordMismatch">
            {{ 'LOGIN.VALIDATOR.PASSWD_MISMATCH' | translate }}
          </li>
          <li *ngIf="passwdForm.newPasswd.invalid && passwdForm.newPasswd.errors?.passwordMatch">
            {{ 'LOGIN.VALIDATOR.MATCH_EXIST_PASSWORD' | translate }}
          </li>
        </ul>
      </section>
    </section>

    <hr />

    <form (ngSubmit)="onSubmit()" [formGroup]="changePasswdForm">
      <span class="p-float-label">
        <input
          pPassword
          required
          type="password"
          id="new_passwd"
          formControlName="newPasswd"
          name="new_passwd"
          autocomplete="new-password"
          [promptLabel]="'USER_PROFILE.PASSWD.NEW_PASSWD' | translate"
          [pKeyFilter]="passwordFilter"
          [feedback]="false"
          (keyup)="!enterCheck($event) || confirmPasswd?.focus()" />
        <label for="new_passwd">{{ 'LOGIN.PASSWORD' | translate }}</label>
      </span>

      <span class="p-float-label">
        <input
          pPassword
          required
          #confirmPasswd
          type="password"
          id="confirm_passwd"
          formControlName="confirmPasswd"
          name="confirm_passwd"
          autocomplete="new-password"
          [promptLabel]="'USER_PROFILE.PASSWD.CONFIRM_PASSWD' | translate"
          [pKeyFilter]="passwordFilter"
          [feedback]="false"
          (keyup)="!enterCheck($event) || onPasswordChange()" />
        <label for="confirm_passwd">{{ 'LOGIN.CONFIRM_PASSWORD' | translate }}</label>
      </span>

      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'primary'"
        [label]="'SECURITY.PWD_CHANGE_AND_LOGIN' | translate"
        [disabled]="reseting || changePasswdForm?.invalid"
        (click)="onPasswordChange()">
        <span *ngIf="reseting" class="spinner-border spinner-border-sm"></span>
      </button>
    </form>
  </section>
</ng-template>

<ng-template #mfaCheck>
  <section id="mfa_check">
    <section class="info_header">
      <div>
        <button pButton aria-label="Button" type="button" [faIcon]="fas.faArrowLeft" (click)="goBack()"></button>
      </div>
      <h4>{{ 'LOGIN.MFA_SIGN_IN' | translate }}</h4>
    </section>

    <section *ngIf="mfaInfo" class="mfa_info">
      <ol>
        <li>{{ 'LOGIN.MFA.STEP_1' | translate }}</li>
        <li>
          <span class="text-muted">{{ 'LOGIN.MFA.APPS' | translate }}</span>
        </li>
        <li>{{ 'LOGIN.MFA.STEP_2' | translate }}</li>
        <li>{{ 'LOGIN.MFA.STEP_3' | translate }}</li>
      </ol>
    </section>

    <section *ngIf="mfaInfo" id="mfa_setup_info">
      <img [src]="mfaInfo?.qrCodeSetupImageUrl" aria-label="mfa info" />
      <p *ngIf="!displayManualKey">
        <u class="text-primary" (click)="displayManualKey = true">{{ 'LOGIN.MFA.REVEAL_KEY' | translate }}</u>
      </p>
      <p *ngIf="displayManualKey">
        {{ 'LOGIN.MFA.SETUP_KEY' | translate }}:
        <span [textContent]="mfaInfo?.manualEntryKey"></span>
      </p>
    </section>

    <section *ngIf="mfaInfo" class="split_line">
      <hr />
    </section>

    <section id="otp_inputs">
      <p>{{ 'LOGIN.MFA.ENTER' | translate }}</p>
      <div class="otp_input_area">
        <div></div>
        <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
          <input
            required
            autofocus
            [type]="'text'"
            [id]="'otp_' + index"
            [maxlength]="1"
            [autofocus]="index === 0"
            [autocomplete]="'off'"
            [ngModel]="otpKeys[index]"
            (ngModelChange)="otpKeys[index] = num($event)"
            [pKeyFilter]="otpFilter"
            (keyup)="OTPKeyInput(index, $event)" />
        </ng-container>
        <div></div>
      </div>
    </section>

    <section>
      <button
        pButton
        aria-label="Button"
        type="button"
        [severity]="'primary'"
        [label]="'LOGIN.LOGIN_IN' | translate"
        [disabled]="loading || !otpFormatPass"
        (click)="otpLogin()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
      </button>
    </section>
  </section>
</ng-template>

<ng-container *ngIf="loginErrorMessage">
  <p-dialog
    styleClass="login_dialog"
    [modal]="true"
    [dismissableMask]="true"
    [visible]="!!loginErrorMessage"
    (visibleChange)="!$event && (loginErrorMessage = undefined)">
    <ng-template pTemplate="header">
      <i class="pi pi-info-circle text-dark"></i>
      <p>{{ 'LOGIN.LOGIN_FAILED' | translate }}</p>
    </ng-template>
    <p>{{ loginErrorMessage }}</p>
  </p-dialog>
</ng-container>

<div *ngIf="testingFeature" id="captured_img"></div>
