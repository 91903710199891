// Angular
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// Primeng
import { SelectItem } from 'primeng/api';
// Rxjs
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
// Caloudi
import { BaseComponent } from '@base';
import { AuthService, SSORequestConfig } from '@core/service/api';
import { CommonValidatorService } from '@core/service/validator';
import { ValidatorUtil } from '@util';
// Interface
import { DocumentLangType } from '@core/enum';
import { AuthUser, CommonHttpErrorResponse, MFAInfo, PreAuthStatus } from '@core/model';
import { PasswordPolicy } from '@sys-admin/model';
// Third Party
import html2canvas from 'html2canvas';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.sass'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('initAnimation', [
      transition(':enter', [
        style({ transform: 'translate(100%, 0)' }),
        animate('.4s cubic-bezier(0, 0, 0.2, 1)', style({ transform: 'translate(0, 0)' })),
      ]),
    ]),
  ],
})
export class LoginComponent extends BaseComponent implements OnDestroy, OnInit {
  public DocumentLang = DocumentLangType;

  public testingFeature: boolean = false;

  public loginItem: LOGIN_ITEM = {
    userid: '',
    passwd: '',
    valid: true,
  };

  @ViewChild('passwd') private readonly passwdInput: ElementRef<HTMLInputElement>;
  @ViewChild('userid') private readonly useridInput: ElementRef<HTMLInputElement>;

  public loading: boolean;
  public submitted: boolean;
  public idIsDirty: boolean;
  public passwdIsDirty: boolean;
  public idValid: boolean;
  public mailValid: boolean;
  public passwdValid: boolean;

  public userPasswd: string;
  public loginErrorMessage: string;
  public passwordFilter: RegExp = /[^\s]+/;
  public otpFilter: RegExp = /[0-9]{1,1}/;

  // Change Password
  public preAuthStatus: PreAuthStatus;
  public changePasswdForm: FormGroup<PasswdFromGroup>;
  public displayPasswdChange: boolean;
  public reseting: boolean;
  public classesMismatch: string;
  public lengthMismatch: string;
  public matchUserId: string;
  public signInAzure: string;
  public signInGCP: string;
  public signInAWS: string;

  public get passwdForm(): PasswdFromGroup {
    return this.changePasswdForm?.controls;
  }

  public ssoRequestConfig: SSORequestConfig;
  public AUTHORITY = AUTHORITY;

  private loginSub: Subscription;
  private loginSub2: Subscription;
  private readonly passSub: Subscription;

  // MFA
  public displayMFA: boolean;
  public displayManualKey: boolean;
  public otpFormatPass: boolean;
  public mfaInfo: MFAInfo;
  public otpKeys: number[] = [];

  /** TODO: language change by browser */
  public langList: SelectItem<DocumentLangType>[] = [
    { value: DocumentLangType.ENG, label: 'English' },
    { value: DocumentLangType.CHS, label: '简体中文' },
    { value: DocumentLangType.CHT, label: '繁體中文' },
  ];

  public selectedLang: SelectItem<string> = this.langList[0];

  constructor(
    public readonly injector: Injector,
    private readonly formBuilder: FormBuilder,
    private readonly validator: CommonValidatorService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super(injector);
    // Redirect to home if already logged in
    if (this.authenticationService?.currentUser?.value?.userProfile) {
      location.href = location.origin + '/pages';
      return;
    }

    if (this.langFeature) {
      this.logger.debug('lang init:', [navigator.language, navigator.languages]);
      this.store
        .select(this.selectors.layout)
        .pipe(first())
        .subscribe(res => {
          this.logger.debug('lang store:', [res]);
          const lang = res?.lang || navigator.language;
          switch (lang) {
            case 'zh-TW':
              this.bindLang(DocumentLangType.CHT);
              break;
            case 'zh-CN':
              this.bindLang(DocumentLangType.CHS);
              break;
            default:
              this.bindLang(DocumentLangType.ENG);
              break;
          }
        });
    }
    this.logger.debug('history:', [history.state]);
  }

  /** Toggle Language Selector & auto select lang on init */
  public langFeature: boolean = true;

  private trans(key: string): string {
    return this.lang('LOGIN.SIGNIN', { authority: key });
  }

  /**
   * On init
   */
  public ngOnInit(): void {
    this.authService.getSSORequestConfig().subscribe({
      next: ssoRequestConfig => (this.ssoRequestConfig = ssoRequestConfig),
      error: error => {
        this.loading = false;
        throw error;
      },
    });
    this.signInAzure = this.trans('Azure');
    this.signInGCP = this.trans('Google');
    this.signInAWS = this.trans('Amazon');
  }

  public onLangSelect(key: DocumentLangType): void {
    this.logger.debug('selected lang:', [key]);
    this.bindLang(key);
  }

  private bindLang(key: DocumentLangType): void {
    this.document.documentElement.lang = key;
    this.selectedLang = this.langList.find(item => item.value === key);
    this.translate.use(key);
    this.signInAzure = this.trans('Azure');
    this.signInGCP = this.trans('Google');
    this.signInAWS = this.trans('Amazon');
    this.store.dispatch(this.actions.ChangeLanguageAction({ lang: key }));
  }

  /**
   * Redirect to Azure Active Directory
   */
  public onSSO(authority: AUTHORITY): void {
    if (authority === AUTHORITY.AZURE) location.href = this.ssoRequestConfig.azureSSORequestUrl;
    if (authority === AUTHORITY.GCP) location.href = this.ssoRequestConfig.gcpSSORequestUrl;
  }

  public idDirty(e: FocusEvent): void {
    this.idIsDirty = true;
    this.userIdChange((<FocusInput>e).target.value.trim());
  }

  public passwdDirty(e: FocusEvent): void {
    this.passwdIsDirty = true;
    this.userPasswdChange((<FocusInput>e).target.value.trim());
  }

  public userIdChange(userId?: string): boolean {
    // this.logger.debug('id change:', [userId]);
    if (userId) this.loginItem.userid = userId;
    this.idValid = this.loginItem?.userid.length > 0;
    this.mailValid = ValidatorUtil.mailValidate(this.loginItem?.userid);
    this.loginItem.valid = this.idValid && this.mailValid;
    return this.idValid && this.mailValid;
  }

  public userPasswdChange(passwd?: string): boolean {
    // this.logger.debug('pass change:', [passwd]);
    if (passwd) this.loginItem.passwd = passwd;
    this.passwdValid = this.loginItem?.passwd.length > 0;
    this.loginItem.valid = this.passwdValid;
    return this.passwdValid;
  }

  public onSubmit(): void {
    this.submitted = true;
    this.preAuthStatus = undefined;
    this.loginItem.userid ||= this.useridInput?.nativeElement.value.trim();
    this.loginItem.passwd ||= this.passwdInput?.nativeElement.value.trim();
    this.logger.debug('valid:', [this.loginItem]);
    if (!this.loginItem.valid) return;
    this.loading = true;
    this.loginSub = this.authService.login(this.loginItem.userid, this.loginItem.passwd).subscribe({
      next: authUser => {
        this.logger.debug('authUser:', [authUser]);
        if (!authUser.preAuthStatus.bOK) {
          this.showChangePasswd(authUser);
          return;
        }
        if (authUser.mfaRequired) {
          this.showMFALogin(authUser);
          return;
        }
        this.store.dispatch(this.actions.LoginAction({ authUser: { ...authUser } }));
      },
      error: (e: CommonHttpErrorResponse): void => this.loginError(e),
      complete: () => {
        this.loading = false;
      },
    });
  }

  public enterCheck(event: KeyboardEvent): boolean {
    return event.code === 'Enter' || event.code === 'NumpadEnter';
  }

  private showChangePasswd(authUser: AuthUser): void {
    const policy: PasswordPolicy = authUser.preAuthStatus.passwordPolicy;
    this.logger.debug('login error:', authUser.preAuthStatus);
    this.preAuthStatus = authUser.preAuthStatus;

    this.lengthMismatch = this.languageService.get('LOGIN.VALIDATOR.LENGTH', {
      length: policy.minPasswordLength,
    }).value;
    this.classesMismatch = this.languageService.get('LOGIN.VALIDATOR.CLASSES', {
      length: policy.numberOfCharacterClasses,
    }).value;
    this.matchUserId = this.lang('LOGIN.VALIDATOR.USERID');

    this.changePasswdForm = this.formBuilder.group<PasswdFromGroup>(
      {
        existingPasswd: new FormControl(this.loginItem.passwd, c => Validators.required(c)),
        newPasswd: new FormControl('', [c => Validators.required(c), Validators.minLength(policy.minPasswordLength)]),
        confirmPasswd: new FormControl('', c => Validators.required(c)),
      },
      {
        validators: [
          this.validator.passwordValidator('newPasswd', 'confirmPasswd', true),
          this.validator.passwordValidator('existingPasswd', 'newPasswd'),
          this.validator.passwordClassesValidator('newPasswd', policy.numberOfCharacterClasses),
        ],
      }
    );
    this.logger.debug('form:', [this.passwdForm, this.changePasswdForm, this.loginItem]);
    this.loginErrorMessage = undefined;
    this.displayPasswdChange = true;
  }

  private showMFALogin(authUser: AuthUser): void {
    this.logger.debug('mfa:', [authUser.mfaSetupInfo]);
    this.mfaInfo = authUser.mfaSetupInfo;
    this.displayPasswdChange = false;
    this.displayMFA = true;
  }

  private loginError(httpErrorResponse: HttpErrorResponse): void {
    this.logger.debug('error:', { httpErrorResponse: httpErrorResponse });
    this.loginErrorMessage = (<{ message: string; }>httpErrorResponse?.error)?.message;
    this.loading = false;
  }

  public otpLogin(): void {
    if (!this.otpFormatheck()) return;
    const result = String(this.otpKeys.map(key => key)).replace(/,/g, '');
    this.logger.debug('otpLogin:', [this.otpKeys, result]);
    this.loading = true;
    this.loginSub = this.authService
      .otpLogin(this.loginItem.userid, this.otpKeys.toString().replace(/,/g, ''))
      .subscribe({
        next: authUser => {
          this.logger.debug('otp authUser:', [authUser]);
          this.store.dispatch(this.actions.LoginAction({ authUser: { ...authUser } }));
        },
        error: (e: CommonHttpErrorResponse): void => this.loginError(e),
        complete: () => {
          this.loading = false;
        },
      });
  }

  public OTPKeyInput(index: number, event: KeyboardEvent): void {
    const current = (i: number): HTMLInputElement =>
      this.document.querySelector<HTMLInputElement>(`input#otp_${i + 1}`);
    // if (event instanceof MouseEvent) delete this.otpKeys[index];
    const numVer = RegExp(/Digit|Numpad[0-9]/);
    this.logger.debug('event:', [event, numVer]);
    if (event.code === 'Backspace' && index !== 0) {
      current(index - 2).focus();
      delete this.otpKeys[index - 1];
      this.otpFormatheck();
      return;
    }
    if (event.code === 'ArrowLeft' && index !== 0) current(index - 2).focus();
    if (event.code === 'ArrowRight' && index !== 5) current(index).focus();
    if (this.enterCheck(event) && !RegExp(/[0-9]/).test(this.otpKeys[index]?.toString())) {
      delete this.otpKeys[index];
      this.otpFormatheck();
      return;
    }
    if (this.enterCheck(event)) index === 5 ? this.otpLogin() : current(index).focus();
    if (!numVer.test(event.code)) return;
    if (!RegExp(/[0-9]/).test(event.key)) return;

    this.otpKeys[index] = Number(event.key);
    this.otpFormatheck();
    if (index === 5) {
      this.otpLogin();
      return;
    }
    this.logger.debug('otpkey select:', [current, index, this.otpKeys, event]);
    current(index).focus();
  }

  private otpFormatheck(): boolean {
    this.logger.debug('otpFormatheck:', [this.otpKeys.toString()]);
    return (this.otpFormatPass = RegExp(/[0-9](,[0-9]){5}/).test(this.otpKeys.toString()));
  }

  public onPasswordChange(): void {
    this.reseting = true;
    this.displayMFA = false;
    this.logger.debug('reset passwd:', [this.loginItem]);
    // return;
    this.loginSub2 = this.authService
      .loginWithPasswordChange({
        userLoginPost: { userId: this.loginItem.userid, password: this.loginItem.passwd },
        userPasswordUpdatePost: {
          existingPassword: this.loginItem.passwd,
          newPassword: this.passwdForm.newPasswd.value,
        },
      })
      .subscribe({
        next: authUser => {
          this.logger.debug('authUser:', [authUser]);
          this.mfaInfo = authUser.mfaSetupInfo;
          if (!authUser.preAuthStatus.bOK) {
            this.showChangePasswd(authUser);
            return;
          }
          if (authUser.mfaRequired) {
            this.showMFALogin(authUser);
            return;
          }
          this.store.dispatch(this.actions.LoginAction({ authUser: { ...authUser } }));
        },
        error: (e: CommonHttpErrorResponse): void => this.loginError(e),
        complete: () => {
          this.reseting = false;
        },
      });
  }

  public goBack(): void {
    this.displayPasswdChange = false;
    this.changePasswdForm = undefined;

    this.displayMFA = false;
    this.displayManualKey = false;
    this.otpKeys = [];
    this.otpFormatheck();
  }

  @ViewChild('login') public loginMain: ElementRef<HTMLElement>;
  public capture(): void {
    html2canvas(this.loginMain.nativeElement, { logging: false }).then(canvas => {
      this.logger.debug('canvas:', [canvas]);
      document
        .querySelector('#captured_img')
        .appendChild(document.createElement('img'))
        .setAttribute('src', canvas.toDataURL('image/png'));
    });
  }

  public ngOnDestroy(): void {
    this.loginSub?.unsubscribe();
    this.loginSub2?.unsubscribe();
    this.passSub?.unsubscribe();
  }
}

interface LOGIN_ITEM {
  userid?: string;
  passwd: string;
  passwd2?: string;
  valid: boolean;
}

enum AUTHORITY {
  AZURE = 'azure',
  GCP = 'google',
  AWS = 'amazon',
}

interface FocusInput extends FocusEvent {
  target: HTMLInputElement;
}

interface PasswdFromGroup {
  existingPasswd: FormControl<string>;
  newPasswd: FormControl<string>;
  confirmPasswd: FormControl<string>;
}
